@import url("https://fonts.googleapis.com/css2?family=Bona+Nova:wght@400;700&display=swap");

.footer-top-flex {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: rgb(4, 70, 4);
  opacity: 0.9;
  color: white;
}
.footer-flex {
  display: flex;
  justify-content: space-evenly;
}
.footer-left-content {
  width: 450px;
  font: normal 17px/32px "Bona Nova";
}
.footer-title {
  font: normal bold 24px/1 "Bona Nova";
  margin-bottom: 1.5rem;
}
.footer-second p {
  font: normal 17px/1 "Bona Nova";
  margin-bottom: 15px;
}
.footer-third li {
  font: normal 17px/1 "Bona Nova";
  margin-bottom: 15px;
  list-style: none;
  cursor: pointer;
}
.footer-link {
  text-decoration: none;
  color: white;
}
.footer-link:hover {
  color: yellow;
}
.footer-third ul {
  padding-left: 0 !important;
}
.footer-img-flex {
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
}
.footer-img-flex img {
  width: 80px;
  height: 70px;
  border-radius: 10px;
}
.footer-icon {
  font-size: 25px;
  margin-right: 10px;
}
.footer-icon-two {
  margin-right: 7px;
  font-size: 20px;
}
.button-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}
.footer-btn {
  outline: none;
  border: none;
  background-color: white;
  padding: 5px;
  font: normal bold 17px/1 "Bona Nova";
  color: #1a237e;
}

/* Footer-bottom-Flex */

.footer-bottom-flex {
  background-color: #1b5c1b;
  padding: 3rem 0;
  border-top: 0.1px solid rgb(148, 147, 147);
  opacity: 0.9;
  color: white;
}
.footer-btm-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-copyright {
  font: normal 17px/1 "Bona Nova";
  margin: 0;
}
.map-footer iframe {
  border-radius: 10px;
}
.footer-copyright span a {
  text-decoration: none;
  color: wheat;
  font: normal bold 17px/1 "Bona Nova";
  padding-left: 5px;
  transition: 0.5s all ease;
}
.footer-copyright span a:hover {
  color: yellow;
  text-decoration: underline;
}
/* Media Querry */
@media only screen and (max-width: 1060px) {
  .footer-flex {
    gap: 2rem;
    flex-direction: column;
    padding: 0 20px;
  }
  .footer-title {
    font: normal bold 22px/1 "Bona Nova";
    margin-bottom: 15px;
  }
  .footer-left-content {
    font: normal 15px/27px "Bona Nova";
    width: 100%;
  }
  .footer-copyright {
    font: normal 15px/22px "Bona Nova";
  }
  .footer-top-flex {
    background: linear-gradient(to right, rgb(15, 52, 67), rgb(15, 52, 67));
  }
  .footer-bottom-flex {
    background: linear-gradient(to right, rgb(15, 52, 67), rgb(15, 52, 67));
  }
}
