@import url("https://fonts.googleapis.com/css2?family=Bona+Nova:wght@400;700&display=swap");
.certi-flex {
  display: flex;
  gap: 3rem;
  margin-top: 3rem;
  margin-bottom: 5rem;
}
.cert-left,
.cert-right {
  padding: 20px 40px;
  /* background: linear-gradient(to right, rgb(52, 232, 158), rgb(31, 105, 134)); */
  background-color: rgb(187, 217, 243);
  width: 650px;
  border-radius: 7px;
  transition: 0.5s all ease;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.cert-main-title {
  font: normal bold 30px/1 "Bona Nova";
  text-align: center;
  margin: 3rem 0;
}
.cert-title {
  font: normal bold 28px/1 "Bona Nova";
  text-align: center;
  margin: 1rem 0;
}
.cert-content {
  font: normal 17px/28px "Bona Nova";
  margin-top: 20px;
  text-align: justify;
}
.cert-left:hover,
.cert-right:hover {
  transform: scale(1.1);
}
/* Media Querry */
@media (max-width: 1060px) {
  .certi-flex {
    flex-wrap: wrap;
    margin: 0;
    gap: 2rem;
  }
  .cert-left,
  .cert-right {
    width: 100%;
    padding: 10px 20px;
  }
  .cert-main-title {
    margin: 1.5rem 0;
  }
}
