@import url("https://fonts.googleapis.com/css2?family=Bona+Nova:wght@400;700&display=swap");
.product-head img {
  width: 100%;
  height: 70vh;
  object-fit: cover;
}
.product-title {
  margin: 3rem 0;
  text-align: center;
  font: normal bold 30px/1 "Bona Nova";
}
/* Product Body Section */

.product-list-flex img {
  max-width: 100%;
  width: 550px;
  height: 350px;
  object-fit: cover;
  /* border-bottom-right-radius: 35px;
  border-top-left-radius: 35px; */
  border-radius: 20px;
  transition: 0.5s all ease-in;
}
.product-main {
  position: relative;
}
.product-caption {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  color: rgb(4, 70, 4);
  text-transform: uppercase;
  opacity: 0;
  transition: all 0.5s ease;
}.product-caption h3{
  font: normal bold 30px/1 "Bona Nova";
  color: whitesmoke;

}
.product-caption:hover {
  opacity: 1;
  /* border: 2px solid rgb(4, 70, 4); */
  background: linear-gradient(to right, rgb(15, 52, 67), rgb(15, 52, 67));
  opacity: 0.6;
  transform: scale(1.08);
  border-radius: 10px;
  bottom: 52%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  font: normal bold 40px/1 "Bona Nova";
}

.product-list-flex {
  display: flex;
  margin-top: 3rem;
  margin-bottom: 5rem;
  gap: 3rem;
  justify-content: center;
}

/* Media Querry */
@media (max-width: 1060px) {
  .product-head img {
    height: auto;
  }
  .product-title {
    font: normal bold 25px/1 "Bona Nova";
    margin: 2rem 0;
  }
  .product-list-flex {
    margin: 1rem 10px;
  }
}
