@import url("https://fonts.googleapis.com/css2?family=Bona+Nova:wght@400;700&display=swap");

.nav-link:hover {
  color: #2f2f2f;
  transform: scale(1.1);
}

.Navbar {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e7e7e7;
}
.company-name {
  margin: 0;
  font: normal bold 18px/1 "Bona Nova";
  text-transform: uppercase;
}
.nav-img-div {
  display: flex;
  align-items: center;
  gap: 6px;
}
.nav-top-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5rem;
}
.nav-logo {
  width: 90px;
  object-fit: cover;
  border-radius: 10px;
}

.nav-items > a {
  font: normal normal bold 17px/1 "Bona Nova";
  text-decoration: none;
  text-transform: uppercase;
  margin: 20px;
  position: relative;
  letter-spacing: 0.5px;
  cursor: pointer;
  color: black;
}
.Navbar > .nav-items {
  margin-right: 30px;
}
.Navbar > .nav-items > a:hover {
  opacity: 1;
  color: #f75023;
  transition: all 0.5s ease-in-out;
}
.Navbar > .nav-items > a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  transition: all 0.45s;
}
.Navbar > .nav-items > a:hover::before {
  width: 100%;
}
.Navbar > .nav-toggle {
  display: none;
}

/* Media Querry */

@media (max-width: 1060px) {
  .nav-items {
    position: absolute;
    top: 0px;
    display: flex;
    flex-direction: column;
    /* background: rgb(4, 70, 4); */
    background: linear-gradient(to right, rgb(15, 52, 67), rgb(15, 52, 67));
    border-radius: 15px;
    width: 70%;
    height: 60%;
    color: white;
    transform: translateX(-100%);
    transition: all 0.45s;
    gap: 2rem;
    left: 0;
  }
  .nav-logo {
    width: 70px;
  }
  .nav-top-flex {
    justify-content: left;
  }
  .Navbar {
    /* background-color: rgb(4, 70, 4); */
    background: linear-gradient(to right, rgb(15, 52, 67), rgb(15, 52, 67));
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;
  }
  .nav-items > a {
    color: white;
    font: normal bold 17px/1 "Bona Nova";
    margin: 5px;
  }
  .nav-items.open {
    transform: translateX(0);
    z-index: 111;
  }
  .Navbar > .nav-toggle {
    display: flex;
    width: 50;
    height: 40px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 20px;
    padding-right: 15px;
    padding-left: 10px;
    padding-top: 5px;
    border-radius: 5px;
    background-color: rgb(228, 192, 192);
  }
  .nav-toggle > .bar {
    position: relative;
    width: 32px;
    height: 2px;
    background: #ffffff;
    transition: all 0.45s ease-in-out;
  }
  .nav-toggle > .bar::before,
  .nav-toggle > .bar::after {
    content: "";
    position: absolute;
    height: 2px;
    background: rgb(4, 70, 4);
    border-radius: 2px;
    transition: all 0.45s ease-in-out;
  }
  .nav-toggle > .bar::before {
    width: 25px;
    transform: translateY(-8px);
    right: 0;
  }
  .nav-toggle > .bar::after {
    width: 32px;
    transform: translateX(8px);
  }
  .nav-toggle.open > .bar {
    transform: translateX(-40px);
    background: transparent;
  }
  .nav-toggle.open > .bar::before {
    width: 32px;
    transform: rotate(45deg) translate(26px, -26px);
  }
  .nav-toggle.open > .bar::after {
    transform: rotate(-45deg) translate(26px, 26px);
  }
}
